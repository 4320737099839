<script setup lang="ts">
  import { MpIcon, MpText } from '@borg/ui';

  const jobCard = useJobCard.inject();
</script>

<template>
  <span class="info">
    <MpIcon
      icon="pin"
      style="color: var(--mp-color-secondary-default)"
    />
    <MpText
      v-if="jobCard.job?.location"
      class="info__text"
      no-margin
    >
      {{ jobCard.job.location }}
    </MpText>
  </span>
</template>

<style scoped lang="scss">
  .info {
    display: flex;
    align-items: flex-start;
    gap: var(--mp-space-30);

    &__text {
      margin-top: 2px;
    }
  }
</style>
